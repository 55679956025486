<template>
  <el-form :model="addForm" ref="addFormRefs" :rules="addFormRules" label-width="100px" class="addForm">
    <div class='disposition_box'>
      <el-form-item label="大屏名称:" prop="screen_name" class="disposition_box_item" key="screen_name">
        <el-input placeholder="请输入大屏名称" autocomplete="off" maxlength="15" :value="addForm.screen_name"
          @input="(e) => (addForm.screen_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="配置类型:" class="disposition_box_item" key="screen_display_type">
        <el-select v-model="addForm.screen_display_type" placeholder="请选择类型" @change="displayTypeChange">
          <el-option v-for="item in getOptions('ScreenDisplayType')" :key="item.value" :label="item.name"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模式:" class="disposition_box_item" v-if="!domain3d" key="screen_type">
        <el-select v-model="addForm.screen_type" placeholder="请选择大屏类型" @change="changeScreenType">
          <el-option v-for="item in getOptions('ScreenType')" :key="item.value" :label="item.name"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="主题:" class="disposition_box_item" v-if="!domain3d" key="screen_model">
        <el-select v-model="addForm.screen_model" placeholder="请选择大屏模式" @change="screenModelChange">
          <el-option v-for="item in getOptions('ScreenModel')" :key="item.value" :label="item.name"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="box3Ddomain" label="域名地址:" prop="screen_3D_domain" v-if="domain3d" key="screen_3D_domain">
        <el-input placeholder="域名地址" class="disposition_box_item_address" autocomplete="off"
          v-model="addForm.screen_3D_domain" :value="data.screen_3D_domain"></el-input>
      </el-form-item>
    </div>
    <div class="content_box" v-show="!domain3d">
      <!-- 中间标准间加删除按钮 -->
      <!-- <div class="delIcon" v-for="(item, j) in addForm.children" :key="j" :id="`standard_${j}`" :style="`top: ${getTop(item.location.top_disk)}px;left: ${getLeft(item.location.left_disk)+335+box2BoxMoveLocation(JSON.parse(JSON.stringify(item.location))).wide_fill}px;`">
        <div class="close_box" @click.stop.prevent="delManiStandard(j)">
          <i class="el-icon-close"></i>
        </div>
      </div> -->
      <div class="left_standard_box">
        <span class="title_box">标准件</span>
        <div class="search_box">
          <el-input placeholder="请输入标准件名称" autocomplete="off" maxlength="15"
            @keyup.enter.native="fnGetBigScreenStandardList" :value="queryForm.standard_name"
            @input="(e) => (queryForm.standard_name = validSpace(e))">
            <i slot="suffix" class="el-icon-search" @click="fnGetBigScreenStandardList"></i></el-input>
        </div>
        <!-- 左侧标准件 -->
        <ul class="standard_box" v-if="!noResult">
          <!-- 背景类型的标准件不做展示所以剔除 -->
          <li class="standard_type_item" v-for="(item, index) in standardList.slice(0, -1)" :key="item.value">
            <div class="standard_title" @click="openStandardType(index)">
              <!-- <span :class="['standard_title_span', currentIndex === index ? 'current_title' : '']"> -->
              <span class="standard_title_span">
                <!-- <span class="current_title" v-show="currentIndex === index"></span> -->
                {{ getWayName("StandardType", item.value) }}
              </span>
              <!-- <i :class="currentIndex === index? 'el-icon-arrow-down': 'el-icon-arrow-right'"></i> -->
              <i :class="currentShowList.indexOf(index + 1) !== -1 ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
            </div>
            <ul class="standard_type_item_box" v-if="currentShowList.indexOf(index + 1) !== -1">
              <template v-if="item.children.length > 0">
                <li :class="[
                  'clickBox',
                  'standard_item',
                  'standard_item_' + data.standard_model,
                  // standardIndex == j ? 'current' : '',
                ]" v-for="(data, j) in item.children" :id="`standard_item_${j}`" :key="j">
                  <span class="title">{{ data.standard_name }}</span>
                  <div class="item_box" @mousedown.stop.prevent="StandardHandleDown(j, $event, data)">
                    <!-- ${data.block_enum} -->
                    <!-- 标准间图片 -->
                    <!-- {{ typeImg(data) }} -->
                    <img :src="require(`@/assets/images/standard/${typeImg(data)}`)
                      " alt sizes srcset />
                  </div>
                </li>
              </template>
              <template v-else>
                <li class="empty_box">
                  <img src="@/assets/images/empty.png" alt />
                  <span>暂无数据</span>
                </li>
              </template>
            </ul>
          </li>
        </ul>
        <div v-else class="noResult">
          <div>暂无此标准件</div>
        </div>
      </div>
      <!-- 中间地图区 -->
      <ul class="main_body_box" id="mainBodyBox" ref='mainBodyBox' :style="addForm.screen_model == 1
          ? `background-image: url(${require('@/assets/images/bg.png')});`
          : ''
        ">
        <!-- 地图版本-覆盖在报表版本上 -->
        <li class="map" id="mapBox" v-show="addForm.screen_type == 1" @click="clickMap"></li>
        <!-- 表格线 -->
        <li :style="{ display: showTable == true ? '' : 'none' }">
          <table border="0" style="border-collapse: collapse" cellspacing="0" cellpadding="0" class="main_table_line">
            <tr v-for="(item, index_w) in rows" :key="index_w">
              <td v-for="(item, index_h) in cols" :id="'td_w' + index_w + 'h' + index_h" :key="index_h" class="td"
                :style="{ width: getWideFill(1) + 'px', height: (getHighFill(1)) + 'px', 'box-sizing': 'border-box' }">
              </td>
            </tr>
          </table>
        </li>
        <li :class="[
          'main_standard_item',
          'standard_item_' + addForm.screen_model,
        ]" v-for="(item, j) in addForm.children" :key="j" :id="`standard_${j}`" :style="`top: ${getTop(item.location.top_disk)}px;
        left: ${getLeft(item.location.left_disk)}px;
        width:${getWideFill(item.location.wide_fill)}px;
        height:${getHighFill(item.location.high_fill)}px;`">
          <span class="title" v-if="isShow(item.block_enum)">{{ item.standard_name }}</span>
          <!-- <span class="title" v-if="isShow(item.block_enum)">{{ item.title }}</span> -->
          <div class="item_box">
            <img :src="require(`@/assets/images/standard/${typeImg(item)}`)
              " alt sizes srcset />
          </div>
          <div class="close_box" @click.stop.prevent="delManiStandard(j)">
            <i class="el-icon-close"></i>
          </div>
        </li>
        <li :class="[
          'main_standard_item',
          'move',
          'standard_item_' + addForm.screen_model,
        ]" v-for="(item, s) in leftList_move" :key="'move' + s" :id="'standard_move_' + s" :style="`top: ${item.location.top_disk}px;
        left: ${item.location.left_disk}px;
        width:${item.location.wide_fill}px;
        height:${item.location.high_fill}px;`" @mousedown.stop.prevent="leftHandleDown(s, $event)">
          <div class="item_box">
            <img :src="require(`@/assets/images/standard/${typeImg(item)}`)
              " alt sizes srcset />
          </div>
        </li>
      </ul>
      <!-- 右侧配置框 -->
      <!--  右侧回显点击 -->
      <div class="right-drawer_box" v-if="isrightDrawer && !noResult && !showMapConfig">
        <el-form :model="addForm.children[standardIndex]" label-width="50px" class="standardForm">
          <!-- {{standardList[currentIndex].children[standardIndex]}} -->
          <el-form-item label="名称:">
            <el-input placeholder="请输入名称" autocomplete="off" maxlength="15"
              v-model="addForm.children[standardIndex].standard_name"
              @input="(e) => (addForm.children[standardIndex].standard_name = validSpace(e))"></el-input>
          </el-form-item>
          <!-- 根据block_enum展示右侧的配置栏 -->
          <!-- 设备--- -->
          <el-form-item label="数据:" v-if="showRightDigital(addForm.children[standardIndex].block_enum)">
            <el-checkbox-group v-model="addForm.children[standardIndex].select_data" :min="1">
              <el-checkbox v-for="(item) in deviceData" :label="item._id"
                :key="item._id">{{ item.standard_data_name }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 长沙地图--- -->
          <el-form-item label="统计:" v-if="showRightStatisticsCS(addForm.children[standardIndex].block_enum)">
            <el-checkbox-group v-model="addForm.children[standardIndex].select_data" :min="1"
              class="checkedRightStatisticsBox">
              <el-checkbox v-for="item in mapStatistics" :label="item._id"
                :key="item._id">{{ item.standard_data_name }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 中国地图--- -->
          <el-form-item label="统计:" v-if="showRightStatisticsZG(addForm.children[standardIndex].block_enum)">
            <el-checkbox-group v-model="addForm.children[standardIndex].select_data" :min="1"
              class="checkedRightStatisticsBox">
              <el-checkbox v-for="item in mapStatisticsChina" :label="item._id"
                :key="item._id">{{ item.standard_data_name }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 异常事件概览 -->
          <el-form-item label="统计:" v-if="showRightStatistics2(addForm.children[standardIndex].block_enum)">
            <el-checkbox-group v-model="addForm.children[standardIndex].select_data" :min="1"
              class="checkedRightStatisticsBox">
              <el-checkbox v-for="item in alarmIssusStatistics" :label="item._id"
                :key="item._id">{{ item.standard_data_name }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 报表类型+样式 -->
          <el-form-item label="类型:" v-if="showRightSelect(addForm.children[standardIndex].block_enum)">
            <el-select v-model="addForm.children[standardIndex].select_graph_type" placeholder="请选择类型"
              @change="$forceUpdate()">
              <el-option v-for="(item, index) in addForm.children[standardIndex].graph_type" :key="index"
                :label="getWayName('GraphType', item.graph_type)" :value="item.graph_type">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 报表样式--- -->
          <el-form-item label="样式:" v-if="showRightSelect(addForm.children[standardIndex].block_enum)">
            <el-select v-model="addForm.children[standardIndex].graph_type_style" placeholder="请选择样式"
              @change="$forceUpdate()">
              <el-option
                v-for="(item, index) in addForm.children[standardIndex].graph_type[findGraphIndex].graph_type_style"
                :key="index" :label="`样式${item}`" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <!-- 出入记录--- -->
          <el-form-item label="张数:" v-if="addForm.children[standardIndex].block_enum === 14">
            <el-select v-model="addForm.children[standardIndex].fixation_size" placeholder="请选择张数">
              <el-option v-for="item in addForm.children[standardIndex].fixation_size_list" :key="item"
                :label="`${item}张`" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <!-- 实时抓拍 -->
          <el-form-item label="张数:" v-if="addForm.children[standardIndex].block_enum === 19">
            <el-select v-model="addForm.children[standardIndex].fixation_size" placeholder="请选择张数">
              <el-option v-for="item in  addForm.children[standardIndex].fixation_size_list" :key="item"
                :label="`${item}张`" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <!-- 异常事件告警--- -->
          <el-form-item label="张数:" v-if="addForm.children[standardIndex].block_enum === 32">
            <el-select v-model="addForm.children[standardIndex].fixation_size" placeholder="请选择展示方式">
              <el-option v-for="item in addForm.children[standardIndex].fixation_size_list" :key="item"
                :label="`${item}张`" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <!-- 视屏监控 -->
          <el-form-item label="画幅:" v-if="addForm.children[standardIndex].block_enum === 34">
            <el-select v-model="addForm.children[standardIndex].picture_size" placeholder="请选择画幅">
              <el-option v-for="item in addForm.children[standardIndex].picture_size_list" :key="item" :label="`${item}个`"
                :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="切换:" v-if="addForm.children[standardIndex].block_enum === 34">
            <el-select v-model="addForm.children[standardIndex].interval_time" placeholder="请选择切换时间">
              <el-option v-for="item in addForm.children[standardIndex].interval_time_list" :key="item"
                :label="`${item === 0 ? '无' : item + '秒'}`" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <!-- 停车场总览 -->
          <el-form-item label="统计:" v-if="showParkStatistics(addForm.children[standardIndex].block_enum)">
            <el-checkbox-group v-model="addForm.children[standardIndex].select_data" :min="1"
              class="checkedRightStatisticsBox">
              <el-checkbox v-for="item in parkStatistics" :label="item._id"
                :key="item._id">{{ item.standard_data_name }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <!-- 左侧配置点击 -->
      <div class="right-drawer_box" v-if="isLeftClickDrawer && !noResult && !showMapConfig && !isrightDrawer">
        <el-form :model="standardList[currentIndex].children[leftStandardIndex]" label-width="50px" class="standardForm">
          <!-- {{standardList[currentIndex].children[leftStandardIndex]}} -->
          <el-form-item label="名称:">
            <el-input placeholder="请输入名称" autocomplete="off" maxlength="15"
              v-model="standardList[currentIndex].children[leftStandardIndex].standard_name"
              @input="(e) => (standardList[currentIndex].children[leftStandardIndex].standard_name = validSpace(e))"></el-input>
          </el-form-item>
          <!-- 根据block_enum展示右侧的配置栏 -->
          <!-- 设备--- -->
          <el-form-item label="数据:"
            v-if="showRightDigital(standardList[currentIndex].children[leftStandardIndex].block_enum)">
            <el-checkbox-group v-model="standardList[currentIndex].children[leftStandardIndex].select_data" :min="1">
              <el-checkbox v-for="(item) in deviceData" :label="item._id"
                :key="item._id">{{ item.standard_data_name }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 长沙地图--- -->
          <el-form-item label="统计:"
            v-if="showRightStatisticsCS(standardList[currentIndex].children[leftStandardIndex].block_enum)">
            <el-checkbox-group v-model="standardList[currentIndex].children[leftStandardIndex].select_data" :min="1"
              class="checkedRightStatisticsBox">
              <el-checkbox v-for="item in mapStatistics" :label="item._id"
                :key="item._id">{{ item.standard_data_name }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 中国地图--- -->
          <el-form-item label="统计:"
            v-if="showRightStatisticsZG(standardList[currentIndex].children[leftStandardIndex].block_enum)">
            <el-checkbox-group v-model="standardList[currentIndex].children[leftStandardIndex].select_data" :min="1"
              class="checkedRightStatisticsBox">
              <el-checkbox v-for="item in mapStatisticsChina" :label="item._id"
                :key="item._id">{{ item.standard_data_name }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 异常事件概览 -->
          <el-form-item label="统计:"
            v-if="showRightStatistics2(standardList[currentIndex].children[leftStandardIndex].block_enum)">
            <el-checkbox-group v-model="standardList[currentIndex].children[leftStandardIndex].select_data" :min="1"
              class="checkedRightStatisticsBox">
              <el-checkbox v-for="item in alarmIssusStatistics" :label="item._id"
                :key="item._id">{{ item.standard_data_name }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 报表类型+样式 -->
          <el-form-item label="类型:"
            v-if="showRightSelect(standardList[currentIndex].children[leftStandardIndex].block_enum)">
            <el-select v-model="standardList[currentIndex].children[leftStandardIndex].select_graph_type"
              placeholder="请选择类型" @change="$forceUpdate()">
              <el-option v-for="(item, index) in standardList[currentIndex].children[leftStandardIndex].graph_type"
                :key="index" :label="getWayName('GraphType', item.graph_type)" :value="item.graph_type">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 报表样式--- -->
          <el-form-item label="样式:"
            v-if="showRightSelect(standardList[currentIndex].children[leftStandardIndex].block_enum)">
            <el-select v-model="standardList[currentIndex].children[leftStandardIndex].graph_type_style"
              placeholder="请选择样式" @change="$forceUpdate()">
              <el-option
                v-for="(item, index) in standardList[currentIndex].children[leftStandardIndex].graph_type[findLeftGraphIndex].graph_type_style"
                :key="index" :label="`样式${item}`" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <!-- 出入记录--- -->
          <el-form-item label="张数:" v-if="standardList[currentIndex].children[leftStandardIndex].block_enum === 14">
            <el-select v-model="standardList[currentIndex].children[leftStandardIndex].fixation_size" placeholder="请选择张数">
              <el-option v-for="item in standardList[currentIndex].children[leftStandardIndex].fixation_size_list"
                :key="item" :label="`${item}张`" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <!-- 实时抓拍 -->
          <el-form-item label="张数:" v-if="standardList[currentIndex].children[leftStandardIndex].block_enum === 19">
            <el-select v-model="standardList[currentIndex].children[leftStandardIndex].fixation_size" placeholder="请选择张数">
              <el-option v-for="item in  standardList[currentIndex].children[leftStandardIndex].fixation_size_list"
                :key="item" :label="`${item}张`" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <!-- 异常事件告警--- -->
          <!-- <el-form-item label="展示:" v-if="standardList[currentIndex].children[leftStandardIndex].block_enum===32">
              <el-select v-model="standardList[currentIndex].children[leftStandardIndex].show_type" placeholder="请选择展示方式" >
                <el-option v-for="item in standardList[currentIndex].children[leftStandardIndex].show_type_list" :key="item" :label="item===1?'固定展示':'滚动展示'" :value="item"></el-option>
              </el-select>
            </el-form-item> -->
          <!-- <el-form-item label="张数:" v-if="standardList[currentIndex].children[leftStandardIndex].block_enum===32 && standardList[currentIndex].children[leftStandardIndex].show_type === 1"> -->
          <el-form-item label="张数:" v-if="standardList[currentIndex].children[leftStandardIndex].block_enum === 32">
            <el-select v-model="standardList[currentIndex].children[leftStandardIndex].fixation_size"
              placeholder="请选择展示方式">
              <el-option v-for="item in standardList[currentIndex].children[leftStandardIndex].fixation_size_list"
                :key="item" :label="`${item}张`" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <!-- 视屏监控 -->
          <el-form-item label="画幅:" v-if="standardList[currentIndex].children[leftStandardIndex].block_enum === 34">
            <el-select v-model="standardList[currentIndex].children[leftStandardIndex].picture_size" placeholder="请选择画幅">
              <el-option v-for="item in standardList[currentIndex].children[leftStandardIndex].picture_size_list"
                :key="item" :label="`${item}个`" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="切换:" v-if="standardList[currentIndex].children[leftStandardIndex].block_enum === 34">
            <el-select v-model="standardList[currentIndex].children[leftStandardIndex].interval_time"
              placeholder="请选择切换时间">
              <el-option v-for="item in standardList[currentIndex].children[leftStandardIndex].interval_time_list"
                :key="item" :label="`${item === 0 ? '无' : item + '秒'}`" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <!-- 停车场总览 -->
          <el-form-item label="统计:"
            v-if="showParkStatistics(standardList[currentIndex].children[leftStandardIndex].block_enum)">
            <el-checkbox-group v-model="standardList[currentIndex].children[leftStandardIndex].select_data" :min="1"
              class="checkedRightStatisticsBox">
              <el-checkbox v-for="item in parkStatistics" :label="item._id"
                :key="item._id">{{ item.standard_data_name }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <!-- 背景地图 -->
      <div class="right-drawer_map" v-if="showMapConfig">
        <el-form :model="selectMapConfig" label-width="50px" class="standardForm">
          <el-form-item label="名称:">
            <el-input placeholder="请输入名称" autocomplete="off" maxlength="15" v-model="selectMapConfig.standard_name"
              @input="(e) => (selectMapConfig.standard_name = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="统计:">
            <el-checkbox-group v-model="selectMapConfig.select_data" :min="1" class="checkedRightStatisticsBox">
              <el-checkbox v-for="item in mapConfig" :label="item._id" :key="item._id" :value="item._id">{{
                item.standard_data_name }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-form>
</template>
<script>
import systemapi from '@/api/systemapi'
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import html2canvas from 'html2canvas'
export default {
  props: {
    id: { type: String },
    data: { type: Object },
    backMap: { type: Object },
    isMultiple: { default: false }
  },
  data () {
    return {
      mapSmall: null,
      addForm: {
        screen_name: '', // 大屏名称
        screen_type: 1, // 配置大屏 原 大屏类型  地图版本 = 1, 报表版本 = 2
        screen_display_type: 1, // 类型 2D=1 3D=2
        screen_3D_domain: '', // 大屏3D地址
        screen_model: 1, // 主题 原 大屏模式 深色模式 = 1, 浅色模式 = 2
        map_lng: '', // 地图经度
        map_lat: '', // 地图纬度
        map_zoom: 5, // 地图的缩放层级
        children: [],
        screen_pic: '' // 大屏图片地址
      },
      addFormRules: {
        screen_name: [
          { required: true, message: '请输入大屏名称', trigger: 'blur' }
        ],
        screen_3D_domain: [
          { required: true, message: '请输入大屏跳转域名', trigger: 'blur' }
        ]
      },
      queryForm: {
        standard_name: ''
      },
      // 左侧标准件列表
      standardList: [],
      // 总标准件列表
      allStandardList: [],
      currentIndex: 0,
      leftStandardIndex: 0,
      standardIndex: null,
      // 被拖动的格子集合
      leftList_move: [],
      // 是否展示右侧弹层
      isrightDrawer: false,
      isLeftClickDrawer: false,
      // 行
      rows: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23
      ],
      // 列
      cols: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47
      ],
      containerW: 1200, // 容器宽度
      containerH: 600, // 容器高度
      showTable: false, // 是否显示表格
      // 解决单击事件、与移动事件冲突
      firstTime: null,
      lastTime: null,
      domain3d: false, // 是否是3D类型展示
      itemMInWith: '', // 标准件初始高度
      itemMInHeigh: '',
      itemMinLocation: [],
      currentShowList: [], // 标准件展示
      showDrawer: true, // 抽屉展示
      itemMinWidth: '', // 最小宽度
      itemMinHigh: '',
      delX: '',
      noResult: false, // 搜索不到标准件
      deviceData: [], // 设备标准件的数据配置选项
      mapStatistics: [], // 长沙地图标准件的数据配置选项
      mapStatisticsChina: [], // 中国地图
      alarmIssusStatistics: [], // 异常事件概览
      parkStatistics: [], // 停车场总览
      mapConfig: [], // 地图配置项
      selectMapConfig: {}, // 选择的地图配置项
      showMapConfig: false, // 地图展示配置项
      graphTypeList: [], // 图表样式选择
      fixationSizeSist: [], // 切换样式选择
      intervalTimeList: [], // 切换事件选择
      pictureSizeList: [], // 展示张数选择
      showTypeList: [] // 展示方式选择

    }
  },
  watch: {
    addForm: {
      handler (val) {
        this.$emit('change', val)
      },
      deep: true
    },
    data: {
      handler (val) {
        if (val !== {}) {
          this.initBox(val)
          this.initMap()
        }
        if (val.screen_display_type === 2) {
          this.domain3d = true
        } else {
          this.domain3d = false
        }
      },
      deep: true
    }
  },
  created () {
    // this.addForm = JSON.parse(JSON.stringify(this.data));
    this.$nextTick(() => {
    })
  },
  mounted () {
    var that = this
    var mainBodyBox
    this.$nextTick(() => {
      that.fnGetBigScreenStandardList()
      that.initMap()
      that.fnGetStandardDataList()
      mainBodyBox = document.getElementById('mainBodyBox')
      // offsetHeight的值包括元素内容+内边距+边框， clientHeight的值等于元素内容+内边距。区别就在于有没有边框~
      that.containerW = mainBodyBox.clientWidth
      that.containerH = mainBodyBox.clientHeight
      window.addEventListener('resize', function () {
        that.containerW = mainBodyBox.clientWidth
        that.containerH = mainBodyBox.clientHeight
        // window.location.reload();
      })
    })
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName']),
    // 计算属性的 getter
    getTop () {
      // 到顶部的距离
      const that = this
      // eslint-disable-next-line camelcase
      return top_disk => {
        // eslint-disable-next-line camelcase
        return (that.containerH / that.rows.length) * top_disk
      }
    },
    getLeft () {
      // 到左边的距离
      const that = this
      // eslint-disable-next-line camelcase
      return left_disk => {
        // eslint-disable-next-line camelcase
        return (that.containerW / that.cols.length) * left_disk
      }
    },
    getWideFill () {
      // 宽
      const that = this
      // eslint-disable-next-line camelcase
      return wide_fill => {
        // console.log(that.containerW/that.cols.length * wide_fill )
        // eslint-disable-next-line camelcase
        return (that.containerW / that.cols.length) * wide_fill
      }
    },
    getHighFill () {
      // 高
      const that = this
      // eslint-disable-next-line camelcase
      return high_fill => {
        // eslint-disable-next-line camelcase
        return (that.containerH / that.rows.length) * high_fill
      }
    },
    // 查询index
    findGraphIndex () {
      const graphStyleIndex = this.addForm.children[this.standardIndex].graph_type.findIndex((item) => {
        return item.graph_type === this.addForm.children[this.standardIndex].select_graph_type
      })
      return graphStyleIndex
    },
    findLeftGraphIndex () {
      const graphStyleIndex = this.standardList[this.currentIndex].children[this.leftStandardIndex].graph_type.findIndex((item) => {
        return item.graph_type === this.standardList[this.currentIndex].children[this.leftStandardIndex].select_graph_type
      })
      return graphStyleIndex
    }
  },
  methods: {
    // 初始化盒子数据
    initBox (boxData) {
      this.addForm = JSON.parse(JSON.stringify(boxData))
      this.fnGetBigScreenStandardList()
      console.log('初始化盒子数据', boxData)
      this.leftList_move = boxData.children.map(item => {
        var it = {}
        it.block_enum = item.block_enum
        it.select_graph_type = item.select_graph_type
        it.location = this.box2BoxMoveLocation(
          JSON.parse(JSON.stringify(item.location))
        )
        return it
      })
      if (this.data.screen_display_type === 2) {
        this.domain3d = true
      }
    },
    // 获取标准件列表
    async fnGetBigScreenStandardList () {
      const res = await systemapi.getBigScreenStandardList({
        standard_name: this.queryForm.standard_name,
        standard_model: this.addForm.screen_model
      })
      this.currentShowList = []
      if (res || res.Code === 200) {
        var data = this.getOptions('StandardType').concat()
        // console.log('data', data)
        // 地图 = 1,报表 = 2,指标 = 3
        data.forEach(item => {
          switch (item.value) {
            case 1:
              return (item.children = res.Data.mapls)
            case 2:
              return (item.children = res.Data.reportls)
            case 3:
              return (item.children = res.Data.indexls)
            case 4:
              return (item.children = res.Data.background)
          }
        })
        console.log('fnGetBigScreenStandardList----地图标准件集合1', data)
        // 背景类型的标准件不做展示所以剔除
        // data = data.slice(0, -1)
        this.standardList = data // //地图标准件集合
        if (JSON.stringify(this.backMap) !== '{}' && this.backMap) {
          this.selectMapConfig = this.backMap
        } else {
          this.selectMapConfig = this.standardList[3].children[0]
        }
        console.log('selectMapConfig', this.selectMapConfig)
        this.allStandardList = []
        this.standardList.forEach((item, index) => {
          item.children.forEach(t => {
            // t.select_graph_type = t.graph_type[0];
            t.standard_id = t._id
          })
          if (item.children.length > 0) {
            this.currentShowList.push(index + 1)
          }
          this.allStandardList = [...this.allStandardList, ...item.children]
        })
        const sum = this.standardList.reduce((prev, current) => {
          return prev + current.children.length
        }, 0)
        if (sum === 0) {
          this.noResult = true
          this.standardList = []
        } else {
          this.noResult = false
        }
      } else {
        this.standardList = []
      }
    },
    // 获取标准件筛选列表
    async fnGetStandardDataList () {
      const res = await systemapi.getStandardDataList()
      // 设备配置选项
      this.deviceData = this.filterStandardDataList(res.Data, 12)
      // 长沙地图配置项
      this.mapStatistics = this.filterStandardDataList(res.Data, 4)
      // 中国地图配置项
      this.mapStatisticsChina = this.filterStandardDataList(res.Data, 5)
      // 背景地图
      this.mapConfig = this.filterStandardDataList(res.Data, 28)
      // 异常事件概览配置项
      this.alarmIssusStatistics = this.filterStandardDataList(res.Data, 29)
      // 停车场总览
      this.parkStatistics = this.filterStandardDataList(res.Data, 6)
    },
    // 通过type值筛选标准件列表
    filterStandardDataList (arr, type) {
      const newArr = arr.filter(item => {
        return item.block_enum.indexOf(type) !== -1
      })
      return newArr
    },
    // 图片
    typeImg (item) {
      switch (item.block_enum) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 12:
        case 20:
        case 21:
        case 22:
        case 23:
        case 24:
        case 25:
        case 26:
        case 27:
        case 35:
        case 36:
        case 34:
        case 32:
        case 31:
        case 37:
        case 38:
          // console.log('左侧图片', this.addForm.screen_model, '-', item.block_enum, '-', item.select_graph_type)
          return `${this.addForm.screen_model}-${item.block_enum}-${item.select_graph_type}.png`
        case 10:
        case 11:
        case 15:
        case 16:
        case 17:
        case 18:
        case 30:
        case 33:
        case 13:
          // console.log('左侧图片', this.addForm.screen_model, '-', 8, '-', item.select_graph_type)
          return `${this.addForm.screen_model}-8-${item.select_graph_type}.png`
        case 14:
        case 19:
          // console.log('左侧图片', this.addForm.screen_model, '-', 14, '-', item.select_graph_type)
          return `${this.addForm.screen_model}-14-${item.select_graph_type}.png`
        case 29:
          // console.log('左侧图片29', this.addForm.screen_model, '-', item.block_enum, '-', item.select_graph_type)
          return `${this.addForm.screen_model}-12-${item.select_graph_type}.png`
        case 28:
          console.log('地图', this.addForm)
          return '1-12-6.png'
      }
    },
    isShow (type) {
      return (
        type !== 1 &&
        type !== 2 &&
        type !== 3 &&
        type !== 20 &&
        type !== 21 &&
        type !== 22 &&
        type !== 23 &&
        type !== 24 &&
        type !== 25 &&
        type !== 26 &&
        type !== 27 &&
        type !== 28 &&
        type !== 35 &&
        type !== 36 &&
        type !== 37 &&
        type !== 38
      )
    },
    showRightDigital (type) {
      return (type === 12)
    },
    showRightStatisticsCS (type) {
      return (type === 4)
    },
    showRightStatisticsZG (type) {
      return (type === 5)
    },
    showRightStatistics2 (type) {
      return (type === 29)
    },
    showRightPages (type) {
      return (type === 14 || type === 19 || type === 32)
    },
    showParkStatistics (type) {
      return type === 6
    },
    // 根据不同block_enum展示报表样式折现饼图柱图
    showRightSelect (type) {
      return (
        type === 8 ||
        type === 10 ||
        type === 11 ||
        type === 13 ||
        type === 15 ||
        type === 16 ||
        type === 17 ||
        type === 18 ||
        type === 30 ||
        type === 33
      )
    },
    // 大屏模式下拉列发生改变时触发
    screenModelChange (val) {
      this.fnGetBigScreenStandardList()
      this.initMap()
    },
    // 点击左侧抽屉
    openStandardType (index) {
      const hasit = this.currentShowList.indexOf(index + 1)
      if (hasit === -1) {
        this.currentShowList.push(index + 1)
      } else {
        this.currentShowList.splice(hasit, 1)
      }
    },
    // getLiClick (index) {
    //   console.log('index', index)
    //   if (this.currentIndex === index) {
    //     this.currentIndex = null
    //   } else {
    //     this.currentIndex = index
    //   }
    // },
    // 点击左侧标准件，展示右侧抽屉层
    clickStandard (index) {
      // console.log('clickStandard-----', index)
      // this.standardIndex = index
      // this.isrightDrawer = true
      this.leftStandardIndex = index
      this.isLeftClickDrawer = true
      this.isrightDrawer = false
      this.showMapConfig = false
    },
    // 初始化地图
    initMap () {
      var that = this
      lazyAMapApiLoaderInstance.load().then(() => {
        this.mapSmall = new AMap.Map('mapBox', {
          pitch: 0, // 地图俯仰角度，有效范围 0 度- 83 度
          resizeEnable: true,
          rotateEnable: true,
          pitchEnable: true,
          zoom: 4,
          rotation: 0,
          viewMode: '3D', // 开启3D视图,默认为关闭
          buildingAnimation: true, // 楼块出现是否带动画
          expandZoomRange: true,
          zooms: [3, 18],
          mapStyle:
            that.addForm.screen_model === 1
              ? 'amap://styles/e4500f6a8fc6db8c271dc0f1250134e3'
              : 'amap://styles/whitesmoke',
          // ,"point"
          features: ['bg', 'road', 'building']
        })
        this.mapSmall.clearMap()
        if (that.addForm.map_lng && that.addForm.map_lat) {
          that.mapSmall.setCenter([that.addForm.map_lng, that.addForm.map_lat])
          that.mapSmall.setZoom(that.addForm.map_zoom)
        }
        AMap.event.addListener(that.mapSmall, 'zoomend', function () {
          var zoom = that.mapSmall.getZoom()
          var lnglat = that.mapSmall.getCenter()
          that.addForm.map_lng = lnglat.lng
          that.addForm.map_lat = lnglat.lat
          that.addForm.map_zoom = zoom
        })
        AMap.event.addListener(that.mapSmall, 'mapmove', function () {
          //   console.log('mapmove');
          var zoom = that.mapSmall.getZoom()
          var lnglat = that.mapSmall.getCenter()
          // console.log('mapmove----zoom---', zoom);
          // console.log('mapmove----lnglat----', lnglat);
          that.addForm.map_lng = lnglat.lng
          that.addForm.map_lat = lnglat.lat
          that.addForm.map_zoom = zoom
        })
        var zoom = that.mapSmall.getZoom()
        var lnglat = that.mapSmall.getCenter()
        that.addForm.map_lng = lnglat.lng
        that.addForm.map_lat = lnglat.lat
        that.addForm.map_zoom = zoom
      })
    },
    // 删除已配置的标准件
    delManiStandard (index) {
      this.showMapConfig = false
      this.isrightDrawer = false
      console.log('del', index)
      this.addForm.children.splice(index, 1)
      this.leftList_move.splice(index, 1)
    },
    // 初始化需要拖动的格子
    initleftList_move () {
      this.leftList_move = this.addForm.children.map(item => {
        var it = {}
        it.block_enum = item.block_enum
        it.select_graph_type = item.select_graph_type
        it.location = this.box2BoxMoveLocation(
          JSON.parse(JSON.stringify(item.location))
        )
        return it
      })
    },
    // 鼠标按下左侧标准件
    StandardHandleDown (index, event, data) {
      console.log('左侧', index, data)
      // console.log('left', this.addForm)
      this.firstTime = new Date().getTime()
      // console.log('firstTime---', this.firstTime, index, data, event)
      const clickBoxes = document.querySelectorAll('.clickBox')
      clickBoxes.forEach(item => {
        item.classList.remove('current')
      })
      const clickLi = event.target.parentNode.parentNode
      clickLi.classList.add('current')
      this.currentIndex = data.standard_type - 1 // 定位三种报表中的哪一个
      //  console.log('StandardHandleDown----');
      event = event || window.event
      var item = JSON.parse(
        JSON.stringify(this.standardList[this.currentIndex].children[index])
      )
      var parentBox = document.getElementById('mainBodyBox')
      // 1、将现在这个div添加opacity用于做区分, 创建一个新li用于移动,
      var itemBox = document.getElementById(`standard_item_${index}`)
      // itemBox.style.opacity = 0.5
      var newBox = document.createElement('li')
      newBox.className =
        'main_standard_item standard_item_' + item.standard_model
      var src = require(`@/assets/images/standard/${this.typeImg(
        item
      )}`)
      var str = ''
      if (this.isShow(item.block_enum)) {
        str = `<span class="title">${item.title}</span>`
      }
      newBox.innerHTML =
        str +
        `<div class="item_box" >
              <img src="${src}" alt="" sizes="" srcset="">
            </div>`
      var objX = parentBox.getBoundingClientRect().left // 父盒子距离页面的 x 距离
      var objY = parentBox.getBoundingClientRect().top // 父盒子距离页面的 y 距离
      // top、left值分别为 当前位置 - 鼠标按下到盒子边的位置
      newBox.style.position = 'fixed'
      newBox.style.top = event.clientY - event.offsetY + 'px'
      newBox.style.left = event.clientX - event.offsetX + 'px'
      // width、height 分别为默认宽高*比例；
      //  console.log(item.location);
      // eslint-disable-next-line camelcase
      var move_box_location = this.box2BoxMoveLocation(
        JSON.parse(JSON.stringify(item.location))
      )
      // console.log(move_box_location);
      newBox.style.width = move_box_location.wide_fill + 'px'
      newBox.style.height = move_box_location.high_fill + 'px'
      parentBox.appendChild(newBox)
      // //div的偏移量 鼠标.clientX-元素.offsetLeft
      // //div的偏移量 鼠标.clientY-元素.offsetTop
      var ol = event.clientX - newBox.offsetLeft
      var ot = event.clientY - newBox.offsetTop
      var left = -1
      var top = -1
      var that = this
      // 1.当鼠标在被拖拽元素上按下时，开始拖拽
      document.onmousemove = function (event) {
        that.showTable = true // 显示标尺
        // console.log('onmousemove-----')
        // 设置box1捕获所有鼠标按下的事件
        // setCapture可以将鼠标事件锁定在指定的元素上，当元素捕获了鼠标事件后，该事件只能作用在当前元素上。
        itemBox.setCapture && itemBox.setCapture()
        // 2.当鼠标移动时被拖拽元素跟随鼠标移动
        event = event || window.event
        left = event.clientX - ol
        top = event.clientY - ot
        if (left <= 0) {
          left = 0
        }
        if (top <= 0) {
          top = 0
        }
        newBox.style.left = left + 'px'
        newBox.style.top = top + 'px'
      }

      // //为元素绑定一个鼠标松开函数
      document.onmouseup = function () {
        // 3.当鼠标松开时，被拖拽元素固定在当前位置
        document.onmousemove = null
        document.onmouseup = null
        document.onclick = null
        // 当鼠标松开时取消捕获
        itemBox.releaseCapture && itemBox.releaseCapture()

        that.lastTime = new Date().getTime()
        console.log('lastTime---', that.lastTime)
        if (that.lastTime - that.firstTime <= 500) {
          parentBox.removeChild(newBox)
          itemBox.style.opacity = 1
          that.clickStandard(index) // 触发点击事件
          return
        }
        that.showTable = false // 隐藏标尺
        // 1、如果 移动的盒子在 左侧父盒子内，则将该数据添加到 左侧列表中并删除右侧列表中该数据
        if (
          left - objX < 0 ||
          left - objX >= parentBox.offsetWidth - newBox.offsetWidth ||
          top - objY < 0 ||
          top - objY >= parentBox.offsetHeight - newBox.offsetHeight
        ) {
        } else {
          // 将数据添加到配置列表中
          item.location.left_disk = that.round(
            (left - objX) / (that.containerW / that.cols.length)
          )
          item.location.top_disk = that.round(
            (top - objY) / (that.containerH / that.rows.length)
          )
          that.addForm.children.push(item)
          // console.log('将数据添加到配置列表中', that.addForm.children)
          that.initleftList_move()
          //  console.log(that.leftList_move)
        }
        parentBox.removeChild(newBox)
        itemBox.style.opacity = 1
      }
      return false
    },
    // 将格子单位位置转换为具体像素位置
    box2BoxMoveLocation (location) {
      // console.log('location-----', JSON.stringify(location));
      location.min_wide =
        location.min_wide || this.getWideFill(location.wide_fill)
      location.min_high =
        location.min_high || this.getHighFill(location.high_fill)
      location.left_disk = this.getLeft(location.left_disk)
      location.top_disk = this.getTop(location.top_disk)
      location.wide_fill = this.getWideFill(location.wide_fill)
      location.high_fill = this.getHighFill(location.high_fill)
      // console.log('box2BoxMoveLocation----', location);
      return location
    },
    // 4舍5入
    round (num, is0) {
      num = Math.round(num)
      if (is0) {
        return num
      }
      return num <= 1 ? 1 : num
    },
    // 鼠标按下已配置标准件
    leftHandleDown (index, event) {
      // this.isrightDrawer = false
      this.isLeftClickDrawer = false
      this.isrightDrawer = true
      this.showMapConfig = false
      this.standardIndex = index
      // console.log('鼠标按下已配置标准件', this.addForm)
      // console.log('定位', this.addForm.children[index])
      let originLocation = ''
      this.allStandardList.some((item) => {
        if (item.block_enum === this.addForm.children[index].block_enum) {
          this.addForm.children[index].fixation_size_list = item.fixation_size_list
          this.addForm.children[index].graph_type = item.graph_type
          this.addForm.children[index].interval_time_list = item.interval_time_list
          this.addForm.children[index].picture_size_list = item.picture_size_list
          this.addForm.children[index].show_type_list = item.show_type_list
          // this.itemMinLocation = this.box2BoxMoveLocation(item.location)
          originLocation = item.location
        }
      })
      // console.log(' this.itemMinLocation', this.itemMinLocation)
      console.log('originLocation', originLocation)
      const originMInWidth = (this.containerW / this.cols.length) * originLocation.wide_fill
      const originMinHeight = (this.containerH / this.rows.length) * originLocation.high_fill
      console.log('www', (this.containerW / this.cols.length) * originLocation.wide_fill)
      console.log('hhh', (this.containerH / this.rows.length) * originLocation.high_fill)
      event = event || window.event
      var parentBox = document.getElementById('mainBodyBox')
      var item = this.leftList_move[index]
      // 按下时定义最小宽高
      this.itemMinWidth = item.location.min_wide
      this.itemMInHeigh = item.location.min_high
      // console.log('定义的最小宽高', this.itemMinWidth, this.itemMInHeigh)
      var obj = document.getElementById(`standard_move_${index}`)
      obj.style.opacity = 0.5
      obj.style.zIndex = 1000
      var resizeable = false // 是否开启尺寸修改
      var clientX, clientY // // 鼠标按下时的坐标，并在修改尺寸时保存上一个鼠标的位置
      var direc = '' // 鼠标按下时的位置，使用n、s、w、e表示
      var cursor // 鼠标样式
      // 1、获取鼠标按下的位置，若是在四周边框的位置上，则缩放大小，否则为移动数据
      var d = this.getDirection(event, obj)
      if (d !== '') {
        resizeable = true
        direc = d
        clientX = event.clientX
        clientY = event.clientY
        cursor = d + '-resize'
      } else {
        resizeable = false
        cursor = 'move'
      }
      // 修改鼠标显示效果
      obj.style.cursor = cursor
      // div的偏移量 鼠标.clientX-元素.offsetLeft
      // div的偏移量 鼠标.clientY-元素.offsetTop
      var ol = event.clientX - obj.offsetLeft
      var ot = event.clientY - obj.offsetTop
      var objX = obj.getBoundingClientRect().left // 图片盒子距离页面的 x 距离
      var objY = obj.getBoundingClientRect().top // 图片盒子距离页面的 y 距离
      this.delX = objX
      var that = this
      // eslint-disable-next-line camelcase
      var move_location = {}
      //  console.log(objY);
      // 1.当鼠标在被拖拽元素上按下时，开始拖拽
      document.onmousemove = function (event) {
        that.showTable = true // 显示标尺
        // 2.当鼠标移动时被拖拽元素跟随鼠标移动
        event = event || window.event
        // 设置box1捕获所有鼠标按下的事件
        // setCapture可以将鼠标事件锁定在指定的元素上，当元素捕获了鼠标事件后，该事件只能作用在当前元素上。
        obj.setCapture && obj.setCapture()
        // d = that.getDirection(event, obj);
        //   console.log(d)
        // // 当位置为四个边和四个角时才开启尺寸修改,并修改鼠标样式
        if (d !== '') {
          resizeable = true
          direc = d
          clientX = event.clientX
          clientY = event.clientY
          cursor = d + '-resize'
        } else {
          // resizeable = false;
          cursor = 'move'
        }
        // console.log('cursor----', cursor);
        // 修改鼠标显示效果
        obj.style.cursor = cursor

        // 定义长宽上左的像素变量并初始复制
        // eslint-disable-next-line camelcase
        let move_w = item.location.wide_fill
        // eslint-disable-next-line camelcase
        let move_h = item.location.high_fill
        // eslint-disable-next-line camelcase
        let move_top = item.location.top_disk
        // eslint-disable-next-line camelcase
        let move_left = item.location.left_disk
        console.log('move_w-----', item.location.wide_fill)
        console.log('min_wide-----', item.location.min_wide)
        // 当开启尺寸修改时，鼠标移动会修改div尺寸
        if (resizeable) {
          // 鼠标按下的位置在右边，修改宽度
          if (direc.indexOf('e') !== -1 || direc.indexOf('w') !== -1) {
            var x = event.clientX - objX
            if (x <= originMInWidth) {
              x = originMInWidth
            } else if (x >= parentBox.offsetWidth - item.location.left_disk) {
              x = parentBox.offsetWidth - item.location.left_disk
            }
            // eslint-disable-next-line camelcase
            move_w = x
            clientX = event.clientX
          }
          // // 鼠标按下的位置在上部，修改高度
          // if (direc.indexOf('n') !== -1) {
          //     item.location.high_fill = event.clientY - objY;
          //     clientY = event.clientY
          // }
          // 鼠标按下的位置在底部，修改高度
          if (direc.indexOf('s') !== -1 || direc.indexOf('n') !== -1) {
            var y = event.clientY - objY
            if (y <= originMinHeight) {
              y = originMinHeight
            } else if (y >= parentBox.offsetHeight - item.location.top_disk) {
              y = parentBox.offsetHeight - item.location.top_disk
            }
            // eslint-disable-next-line camelcase
            move_h = y
            clientY = event.clientY
          }
          // // 鼠标按下的位置在左边，修改宽度
          // if (direc.indexOf('w') !== -1) {
          //     item.location.wide_fill = obj.offsetWidth + (clientX - event.clientX);
          //     clientX = event.clientX
          // }
        } else {
          var left = event.clientX - ol
          var top = event.clientY - ot
          if (left <= 0) {
            left = 0
          } else if (left >= parentBox.offsetWidth - obj.offsetWidth) {
            left = parentBox.offsetWidth - obj.offsetWidth
          }
          if (top <= 0) {
            top = 0
          } else if (top >= parentBox.offsetHeight - obj.offsetHeight) {
            top = parentBox.offsetHeight - obj.offsetHeight
          }// eslint-disable-next-line camelcase
          move_top = top
          // eslint-disable-next-line camelcase
          move_left = left
        }
        // eslint-disable-next-line camelcase
        if (move_w < this.itemMinWidth) {
          // eslint-disable-next-line camelcase
          move_w = this.itemMinWidth
        }
        // eslint-disable-next-line camelcase
        if (move_h < this.itemMInHeigh) {
          // eslint-disable-next-line camelcase
          move_h = this.itemMInHeigh
        }
        // eslint-disable-next-line camelcase
        item.location.left_disk = move_left
        // eslint-disable-next-line camelcase
        item.location.top_disk = move_top
        // eslint-disable-next-line camelcase
        item.location.high_fill = move_h
        // eslint-disable-next-line camelcase
        item.location.wide_fill = move_w

        // 计算移动后覆盖了那些格子
        var location = {}
        location.top_disk = that.round(
          // eslint-disable-next-line camelcase
          move_top / (that.containerH / that.rows.length),
          true
        )
        location.left_disk = that.round(// eslint-disable-next-line camelcase
          move_left / (that.containerW / that.cols.length),
          true
        )
        location.high_fill = that.round(// eslint-disable-next-line camelcase
          move_h / (that.containerH / that.rows.length)
        )
        location.wide_fill = that.round(// eslint-disable-next-line camelcase
          move_w / (that.containerW / that.cols.length)
        )
        location.box_index = index
        // eslint-disable-next-line camelcase
        move_location = location
        // console.log('move_location----', move_location)
        var tds = document.getElementsByClassName('td')
        for (var i = 0; i < tds.length; i++) {
          tds[i].style.backgroundColor = 'transparent'
        }
        for (
          var h = location.left_disk;
          h <= location.left_disk + location.wide_fill - 1;
          h++
        ) {
          for (
            var w = location.top_disk;
            w <= location.top_disk + location.high_fill - 1;
            w++
          ) {
            var td = document.getElementById('td_w' + w + 'h' + h)
            td.style.backgroundColor = '#333'
          }
        }
      }
      // 为元素绑定一个鼠标松开函数
      document.onmouseup = function () {
        that.showTable = false // 隐藏标尺
        //   console.log(move_location)
        //   console.log('move_location--------')
        var box = that.addForm.children[move_location.box_index]
        // eslint-disable-next-line camelcase
        var box_move = that.leftList_move[move_location.box_index]

        if (box !== undefined) { // eslint-disable-next-line camelcase
          box.location = move_location
          box_move.location = that.box2BoxMoveLocation(
            JSON.parse(JSON.stringify(move_location))
          )
        }
        // const moveLeftWidth = that.box2BoxMoveLocation(
        //   JSON.parse(JSON.stringify(that.addForm.children.location[0]))
        // )
        // 3.当鼠标松开时，被拖拽元素固定在当前位置
        resizeable = false
        direc = null
        document.onmousemove = null
        document.onmouseup = null
        // 当鼠标松开时取消捕获
        obj.releaseCapture && obj.releaseCapture()

        // 清除td样式
        var tds = document.getElementsByClassName('td')
        for (var i = 0; i < tds.length; i++) {
          tds[i].style.backgroundColor = 'transparent'
        }
        obj.style.opacity = 0
        obj.style.zIndex = 999
      }
      return false
    },
    // 获取鼠标所在div的位置
    getDirection (ev, obj) {
      var xP, yP, offset, dir
      dir = ''
      xP = ev.offsetX
      yP = ev.offsetY
      offset = 10
      //  console.log(xP, yP, obj.offsetHeight, obj.offsetWidth)
      if (yP < offset) dir += 'n'
      else if (yP > obj.offsetHeight - offset) dir += 's'
      if (xP < offset) dir += 'w'
      else if (xP > obj.offsetWidth - offset) dir += 'e'
      //  console.log(dir)
      return dir
    },
    // 表单验证
    onsubmit () {
      this.$refs.addFormRefs.validate(valid => {
        if (valid) {
          this.catchMapPic()
        } else {
          return null
        }
      })
    },
    // 切换2D 3D类型时控制 标准件以及 上方选项的显示与隐藏
    displayTypeChange (val) {
      if (val === 1) {
        this.domain3d = false
      }
      if (val === 2) {
        this.domain3d = true
      }
    },
    // 切换地图报表样式
    changeScreenType (val) {
      console.log(val)
      if (val === 2) {
        this.showMapConfig = false
      }
    },
    // 点击地图背景
    clickMap () {
      this.showMapConfig = true
    },
    // 截取配置好的图片
    async catchMapPic () {
      const canvas = await html2canvas(this.$refs.mainBodyBox, {
        // 配置选项
      })
      const obj = {}
      obj.strbase64 = [canvas.toDataURL()]
      obj.name = 'mapPic.png'
      const res = await systemapi.updataIcon(obj)
      if (res.data.length > 0) {
        this.addForm.screen_pic = res.data[0].FilePath
      } else {
        this.addForm.screen_pic = ''
      }
      if (!this.isMultiple) {
        this.$nextTick(() => {
          this.$emit('onsubmit')
        })
      }
    }
  }
}
</script>
<style scoped lang="less">
.addForm {
  /deep/.el-input {
    width: 280px;
    margin-right: 10px;
  }

  /deep/ .el-select {
    width: 220px;
    margin-right: 10px;
    // .el-input {
    //   width: 100%;
    // }
  }
}

.disposition_box {
  display: flex;
  // flex-wrap: wrap;
  width: 50%;

  .disposition_box_item {
    width: 50%;
    margin-right: 50px;
    // height: 20px;
    // margin-bottom:30px;
  }
}

.disposition_box_item_address {
  width: 650px !important;
}

.content_box {
  // width: 100%;
  // background: #fafafa;
  border: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  // width: fit-content;

  .delIcon {
    display: flex;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px rgba(2, 166, 251, 0.1);
    background-color: #808080;
    color: #fff;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }

  .left_standard_box {
    width: 345px;
    height: 600px;
    background: #fafafa;
    border: 1px solid #e6e6e6;
    // transform: scale(0.8);
    display: flex;
    flex-direction: column;

    .title_box {
      width: 100%;
      height: 44px;
      background: #02a6fb;
      font-size: 18px;
      font-weight: 700;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .search_box {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;

      .el-input {
        width: 100%;
        height: 30px;
        background: #ffffff;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        border: 1px solid #e6e6e6;
      }
    }

    .standard_box {
      flex: 1;
      overflow-y: auto;
      padding: 10px;
      box-sizing: border-box;

      .standard_type_item {
        margin-bottom: 5px;

        .standard_title {
          height: 40px;
          background: #ffffff;
          border-radius: 5px 5px 5px 5px;
          padding: 10px;
          box-sizing: border-box;
          cursor: pointer;

          .standard_title_span {
            display: inline-block;
            height: 100%;
            font-size: 16px;
            color: #4d4d4d;
            display: flex;
            align-items: center;
            position: relative;
            padding-left: 10px;

          }

          .current_title {
            &::before {
              display: inline-block;
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              width: 3px;
              height: 100%;
              background-color: #01a6fb;
            }
          }

          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .standard_type_item_box {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: flex-start;
          margin-top: 10px;

          .standard_item {
            width: 30%;
            margin-bottom: 10px;
            box-shadow: 0px 0px 5px 0px rgba(2, 166, 251, 0.1);
            border-radius: 5px 5px 5px 5px;
            cursor: pointer;
            padding: 10px;
            box-sizing: border-box;
            background: #ffffff;
            margin-right: 10px;
            overflow: hidden;
            /* 超出一行文字自动隐藏 */
            text-overflow: ellipsis;
            /* 文字隐藏后添加省略号 */
            white-space: nowrap;

            /* 强制文本不换行 */
            .title {
              font-size: 12px;
              margin-bottom: 10px;
              color: #666666;
            }

            .item_box {
              width: 100%;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #01a6fb;

              img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
              }
            }
          }

          .current {
            border: 1px solid #02a6fb;
          }
        }
      }
    }

    .noResult {
      box-sizing: border-box;
      width: 100%;
      height: 100%;

      // border:1px solid black;
      div {
        text-align: center;
        margin-top: 50%;
      }

    }

    /deep/ .standard_item_1 {
      .item_box {
        background: #04124c;
      }
    }

    /deep/ .standard_item_2 {
      .item_box {
        background: #ffffff;
      }
    }
  }

  .main_body_box {
    width: 1200px;
    height: 600px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    position: relative;
    box-sizing: border-box;

    //  地图
    #mapBox {
      width: 100%;
      height: 100%;
    }

    // 表格线
    .main_table_line {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      tr {
        border: 1px dashed rgba(0, 191, 255, .5);
        box-sizing: border-box;
      }

      td {
        border: 1px dashed rgba(0, 191, 255, .5);
        box-sizing: border-box;
      }
    }

    // 标准件
    /deep/ .main_standard_item {
      position: absolute;
      box-shadow: 0px 0px 5px 0px rgba(2, 166, 251, 0.1);
      border-radius: 5px 5px 5px 5px;
      cursor: pointer;
      padding: 5px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      z-index: 999;

      .title {
        font-size: 12px;
        margin-bottom: 5px;
      }

      .item_box {
        flex: 1;
        min-width: 1px;
        min-height: 1px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }

      .close_box {
        display: flex;
        position: absolute;
        top: -10px;
        right: -10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-shadow: 0px 0px 5px 0px rgba(2, 166, 251, 0.1);
        background-color: #808080;
        color: #fff;
        font-size: 16px;
        align-items: center;
        justify-content: center;

      }
    }

    /deep/ .move {
      opacity: 0;
    }

    /deep/ .standard_item_1 {
      background: rgba(12, 28, 78, 0.8);

      .title {
        color: #fff;
      }

      .item_box {
        background: rgba(12, 28, 78, 0.8);
        border: 1px solid #01a6fb;
      }
    }

    /deep/ .standard_item_2 {
      background: #ffffff;

      .title {
        color: #666666;
      }

      .item_box {
        background: #ffffff;
        border: 1px solid #01a6fb;
      }
    }
  }

  .right-drawer_box {
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // right: 0;
    height: 600px;
    width: 13%;
    z-index: 3000;
    // background-color: #fff;
    // box-shadow: 10px 10px 10px 10px rgba(2, 166, 251, 0.1);
    padding: 10px;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 50%;
      left: -15px;
      transform: translate(10px, -50%);
      width: 20px;
      height: 90px;
      border-radius: 10px;
      background: #fff;
      text-align: center;
      line-height: 90px;
      cursor: pointer;
      box-shadow: -2px 0 0 0 0 rgba(2, 166, 251, 0.1);

      .iconfont {
        font-size: 20px;
      }
    }

    /deep/ .standardForm {
      .checkedRightStatisticsBox {
        display: flex;
        flex-wrap: wrap;

        .el-checkbox {
          width: 100%;
        }
      }

      .el-select,
      .el-input {
        width: 100%;
        margin-right: 10px;
      }
    }
  }

  .right-drawer_map {
    height: 600px;
    width: 13%;
    // z-index: 2999;
    padding: 10px;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 50%;
      left: -15px;
      transform: translate(10px, -50%);
      width: 20px;
      height: 90px;
      border-radius: 10px;
      background: #fff;
      text-align: center;
      line-height: 90px;
      cursor: pointer;
      box-shadow: -2px 0 0 0 0 rgba(2, 166, 251, 0.1);

      .iconfont {
        font-size: 20px;
      }
    }

    /deep/ .standardForm {
      .checkedRightStatisticsBox {
        display: flex;
        flex-wrap: wrap;

        .el-checkbox {
          width: 100%;
        }
      }

      .el-select,
      .el-input {
        width: 100%;
        margin-right: 10px;
      }
    }
  }
}

.empty_box {
  width: 100%;
  height: 200px;
  font-size: 16px;
  color: #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 50px;
  }
}

.currentClick {
  border: 1px solid #02a6fb;
}

.iconButton {
  background-color: #01a6fb;
  border: 1px;
  color: #01a6fb;
  // box-sizing: border-box;
  color: white;
  margin-left: -9px;
}

/deep/.content_box .left_standard_box .search_box .el-input {
  width: 100% !important;
  display: flex;
  align-items: center;
}

/deep/.el-input__suffix {
  display: flex;
  align-items: center;
}

// .box3Ddomain{
//   // width: 500px;
//   // flex-wrap: wrap;
// }</style>
